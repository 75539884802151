<template>
  <data-form
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm from "../widgets/DataForm.vue";
import BankDataService from "../../services/bank.service";

export default {
  name: "bank-form",
  props: ["data"],
  components: {
    DataForm,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        id: { type: "hidden" },
        name: { label: "Naziv", type: "text" },
        accountNumber: { label: "Broj računa", type: "text" },
        comment: { label: "Komentar", type: "textarray",rows: 4, },
      };
    },
  },
  methods: {
    saveData(newData) {
      if (!(newData.name)) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Naziv je obavezan!",
        });
        return;
      }
      if (!(newData.accountNumber)) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Broj računa je obavezan!",
        });
        return;
      }
      if (newData && newData.id) {
        this.updateBank(newData);
      } else {
        this.createBank(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    updateBank(newData) {
      BankDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Račun je promenjen!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    createBank(newData) {
      BankDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Račun je kreiran!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>