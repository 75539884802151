<template>
  <div class="list row">
    <h2>Banke</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      url="bank"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="Bank"
    >
    </data-table>
  </div>
</template>

<script>
import BankDataService from "../../services/bank.service";
import DataTable from "../widgets/DataTable.vue";
import BankForm from "./BankForm.vue";

export default {
  name: "bank",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: ["id", "name", "accountNumber", "comment", "edit"],
      headers: {
        code: "Id",
        name: "Naziv",
        name_en: "Račun",
        name_cyr: "Komentar",
        edit: "",
      },
      templates: {},
      actions: {
        edit: { commp: BankForm },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: BankForm,
        params: { data: {}, title: "Dodaj nov1 Racun" },
      });
    },
    deleteData(id) {
      BankDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
